<script>
import countTo from "vue-count-to";
// import { BASE_URL } from '../../../baseconstant'
// import SellingProduct from './selling-product'

/**
 * Sales-analytics component
 */
export default {
	components: {
		countTo,
		// SellingProduct,
	},
	props: {
		social_analysis: {},
	},
	data() {
		return {
			series: [
				{
					name: "Airtime",
					data: [],
				},
				{
					name: "Data Bundle",
					data: [],
				},
			],
			chartOptions: {
				chart: {
					stacked: false,
					toolbar: {
						show: false,
					},
				},
				stroke: {
					width: 2,
					curve: "smooth",
				},
				colors: ["#09a3273d", "#ff7800"],
				labels: [],
				markers: {
					size: 0,
				},
				yaxis: {
					title: {
						text: "Total amount",
					},
				},
				tooltip: {
					shared: true,
					intersect: false,
					y: {
						formatter: function(y) {
							if (typeof y !== "undefined") {
								return y.toFixed(0) + " Total amount";
							}
							return y;
						},
					},
				},
				grid: {
					borderColor: "#f1f1f1",
				},
			},
		};
	},
	watch: {
		social_analysis: {
			handler: function(newData) {
				const airtimeData = newData.airtime_monthly_count.map((item) =>
					item.sums.toFixed(0)
				);
				const dataBundleData = newData.data_monthly_count.map((item) =>
					item.sums.toFixed(0)
				);

				// Update chart series
				this.series = [
					{
						name: "Airtime",
						data: airtimeData,
					},
					{
						name: "Data Bundle",
						data: dataBundleData,
					},
				];

				// Update chart options label
				this.chartOptions.labels = newData.airtime_monthly_count.map(
					(item) => item.month_name
				);
			},
			immediate: true, // Trigger the watcher immediately with the initial props value
		},
	},
};
</script>

<template>
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<!-- <div class="float-end">
            <b-dropdown
              variant="white"
              toggle-class="text-reset p-0"
              menu-class="dropdown-menu-end"
            >
              <template v-slot:button-content>
                <span class="fw-semibold">Sort By:</span>
                <span class="text-muted">
                  Yearly
                  <i class="mdi mdi-chevron-down ms-1"></i>
                </span>
              </template>
              <a class="dropdown-item" href="#">Monthly</a>
              <a class="dropdown-item" href="#">Yearly</a>
              <a class="dropdown-item" href="#">Weekly</a>
            </b-dropdown>
          </div> -->
				<h4 class="card-title mb-4">Sales Analytics</h4>

				<div class="mt-1">
					<ul class="list-inline main-chart mb-0">
						<li class="list-inline-item chart-border-left me-0 border-0">
							<h3 class="text-primary">
								₦
								<span data-plugin="counterup">
									<countTo
										:startVal="1"
										:endVal="social_analysis.airtime_sum"
										:duration="2000"
									></countTo>
								</span>
								<span class="text-muted d-inline-block font-size-15 ms-3">Airtime</span>
							</h3>
						</li>
						<li class="list-inline-item chart-border-left me-0">
							<h3>
								₦
								<span data-plugin="counterup">
									<countTo
										:startVal="1"
										:endVal="social_analysis.data_sum"
										:duration="2000"
									></countTo>
								</span>
								<span class="text-muted d-inline-block font-size-15 ms-3"
									>Data Bundle</span
								>
							</h3>
						</li>
					</ul>
				</div>

				<div class="mt-3">
					<apexchart
						type="line"
						class="apex-charts"
						dir="ltr"
						height="339"
						:options="chartOptions"
						:series="series"
					></apexchart>
				</div>
			</div>
			<!-- end card-body-->
		</div>
		<!-- end card-->
	</div>

	<!-- end col-->
</template>
