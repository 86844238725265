var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("Recent Transactions")]),_c('div',{staticStyle:{"max-height":"336px"},attrs:{"data-simplebar":""}},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-borderless table-centered table-nowrap"},[_c('tbody',_vm._l((_vm.recent_transactions),function(item){return _c('tr',{key:item.id},[_c('td',[_c('h6',{staticClass:"font-size-15 mb-1 fw-normal"},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('td',{staticClass:"text-muted fw-semibold text-end"},[_c('i',{staticClass:"icon-xs icon me-2 text-success",attrs:{"data-feather":"trending-up"}}),_vm._v(" ₦"+_vm._s(item.amount .toString() .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+" ")]),_c('td',[_c('span',{staticClass:"badge font-size-12",class:{
                      'bg-soft-success': item.status === 'delivered',
                      'bg-soft-danger': item.status === ('fail' || 'failed'),
                      'bg-soft-warning': item.status === 'pending',
                    }},[_vm._v(_vm._s(item.status))])]),_c('td',[_c('span',{staticClass:"badge font-size-12",class:{
                      'bg-soft-danger': item.transaction_type === 'outflow',
                      'bg-soft-success': item.status === 'inflow',
                    }},[_vm._v(_vm._s(item.transaction_type))])])])}),0)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }